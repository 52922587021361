<template>
  <div
    class="bg-slate-900 border-b border-slate-700 shadow overflow-x-auto overflow-y-hidden p-4 pb-0"
  >
    <nav
      class="-mb-px flex flex-1 items-center h-8 space-x-1"
      aria-label="Tabs"
    >
      <span
        v-for="tab in timedTabs"
        :key="tab.id"
        @click="selectTab(tab)"
        :title="tab.title"
        class="'border border-transparent shadow text-sm font-medium rounded-t-lg text-slate-500 bg-slate-800 hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 '"
        :class="[
          selectedTab === tab.id
            ? 'bg-slate-500 border-blue-700 text-blue-500'
            : '',
          currentTab === tab.id ? 'bg-slate-500' : 'bg-none',
          'group flex items-center shrink-0 pt-2 border h-8 w-10 py-1 px-2 text-sm font-medium cursor-pointer overflow-hidden',
        ]"
        :aria-current="selectedTab === tab.id ? 'page' : undefined"
      >
        <img
          v-if="
            tab.favIconUrl &&
            !erroredFavIconUrls[tab.favIconUrl] &&
            tab.favIconUrl.indexOf('localhost') == -1
          "
          :src="tab.favIconUrl"
          @error="tabIconError(tab)"
          class="w-6 shrink-0"
        />

        <span v-else>
          <font-awesome-icon
            v-if="selectedTab === tab.id"
            class="w-5 h-5 mt-0.5 text-blue-500"
            icon="fa-solid fa-window-maximize"
          />
          <font-awesome-icon
            v-if="selectedTab != tab.id"
            class="w-5 h-5 mt-0.5 text-slate-500"
            icon="fa-solid fa-window-maximize"
          />
        </span>
      </span>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    tabs: { type: Array, default: () => [] },
    selectedTab: { type: Number, default: () => null },
    currentTab: { type: Number, default: () => null },
    videoTime: { type: Number, default: () => 0 },
  },
  emits: ["tabSelected"],
  data() {
    return {
      erroredFavIconUrls: {},
    };
  },
  computed: {
    timedTabs() {
      return this.tabs.map((tab) => ({
        id: tab.id,
        ...(_.findLast(
          tab.sessions,
          (session) => session.time <= this.videoTime
        ) || {}),
      }));
    },
  },
  methods: {
    selectTab(tab) {
      this.$emit("tabSelected", tab);
    },
    tabIconError(tab) {
      this.erroredFavIconUrls[tab.favIconUrl] = true;
    },
  },
};
</script>
