import "./logger";
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import VueAuth0Plugin, {
  AuthenticationGuard,
  // AuthenticationGuardWithoutLoginRedirect,
} from "vue-auth0-plugin";
import VueClipboard from "vue-clipboard2";
import VueToast from "vue-toast-notification";
import { createHead } from "@vueuse/head";
import "vue-toast-notification/dist/theme-sugar.css";
import Markdown from "vue3-markdown-it";
import VueMobileDetection from "vue-mobile-detection";
import Vue3Sanitize from "vue-3-sanitize";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faMemory,
  faMicrochip,
  faDisplay,
  faHardDrive,
  faInfoCircle,
  faTerminal,
  faLaptop,
  faWindowMaximize,
  faBackward,
  faCirclePlay,
  faNetworkWired,
  faBug,
} from "@fortawesome/free-solid-svg-icons";
import {
  faMarkdown,
  faApple,
  faJira,
  faChrome,
} from "@fortawesome/free-brands-svg-icons";
import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

import store from "./store";

import "./index.css";

import App from "./App.vue";

// import Home from "./pages/Home.vue";
import Dashboard from "./pages/Dashboard.vue";

// dashboard
import Replays from "./pages/dashboard/projects/Replays.vue";
import Projects from "./pages/dashboard/Projects.vue";
import Replay from "./pages/Replay.vue";
import Releases from "./pages/Releases.vue";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy.vue";
import TermsOfService from "./pages/legal/TermsOfService.vue";
import EmbeddedNotifier from "./pages/EmbeddedNotifier.vue";
import Onboarding from "./pages/onboarding/Onboarding.vue";
import JoinBeta from "./pages/onboarding/JoinBeta.vue";
import Team from "./pages/Team.vue";
import Invite from "./pages/onboarding/Invite.vue";
import Auth0Callback from "./pages/Auth0Callback.vue";
import AtlassianCallback from "./pages/AtlassianCallback.vue";
import VueHighlightJS from "vue3-highlightjs";
/* add icons to the library */
library.add(
  faMemory,
  faMicrochip,
  faDisplay,
  faHardDrive,
  faMarkdown,
  faApple,
  faBackward,
  faTerminal,
  faLaptop,
  faWindowMaximize,
  faCirclePlay,
  faInfoCircle,
  faJira,
  faChrome,
  faNetworkWired,
  faBug
);

const routes = [
  {
    name: "auth0-callback",
    path: "/auth0-callback",
    component: Auth0Callback,
    beforeEnter: AuthenticationGuard,
  },
  {
    name: "atlassian-callback",
    path: "/atlassian-callback",
    component: AtlassianCallback,
    beforeEnter: AuthenticationGuard,
  },
  { name: "replay", path: "/replay/:replayId", component: Replay },
  { name: "replay-share", path: "/share/:shareKey", component: Replay },
  {
    name: "beta",
    path: "/beta",
    component: JoinBeta,
    beforeEnter: AuthenticationGuard,
  },
  {
    path: "/",
    component: Dashboard,
    beforeEnter: AuthenticationGuard,
    redirect: "/projects",
    children: [
      {
        name: "projects",
        path: "projects",
        component: Projects,
        children: [
          {
            name: "replays",
            path: ":projectId",
            component: Replays,
          },
        ],
      },
      {
        name: "team",
        path: "/team",
        component: Team,
      },
      {
        name: "onboarding",
        path: "/welcome",
        component: Onboarding,
      },
    ],
  },
  {
    name: "beta",
    path: "/beta",
    component: JoinBeta,
  },
  {
    path: "/invite",
    component: Invite,
  },
  {
    name: "releases",
    path: "/releases",
    component: Releases,
  },
  {
    name: "embeddedNotifier",
    path: "/embedded-notifier",
    component: EmbeddedNotifier,
  },
  {
    name: "privacyPolicy",
    path: "/legal/privacy-policy",
    component: PrivacyPolicy,
  },
  {
    name: "termsOfService",
    path: "/legal/terms-of-service",
    component: TermsOfService,
  },
];

const head = createHead();

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const authEnv = {
  audience: `${import.meta.env.VITE_AUTH0_DOMAIN}/api/v2/`,
  scopes: "given_name profile email offline_access",
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
  redirect_uri: `${window.location.origin}`,
  useRefreshTokens: true,
};

const isEmbedded = window.location.href.endsWith("embed=true");

const app = createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(VueClipboard)
  .use(Markdown)
  .use(VueToast, {
    position: "top-right",
  })
  .use(VueHighlightJS)
  .use(head)
  .use(VueMobileDetection)
  .use(Vue3Sanitize, {
    allowedAttributes: {
      "*": ["style"],
    },
  })
  .use(VueVirtualScroller);

if (!isEmbedded) {
  app.use(VueAuth0Plugin, authEnv);
}

app.mount("#app");
